import React from "react";
import { Row, Col } from "react-bootstrap";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import Title from "../ui-components/title/title";
// Assets
import Viola from "../../assets/about/viola.jpg";

const About = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="ABOUT ME" />
      <p className="font12">
        Emerging from Dublins's art scene, I work in a variety of industries capturing moments on film.
      </p>
      <Row>
        <Col md={12} lg={4}>
          <TeamBox avatar={Viola} name="Viola Boe" job="Photographer" />
        </Col>
      </Row>
    </div>
  </div>
);

export default About;
