// src/pages/Home.js
import React from "react";
import Photos from "../../components/photos/photos";

const PhotosPage = () => (
  <div>
    <Photos />
  </div>
);

export default PhotosPage;
