import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Updated path// Assets

// Import Swiper styles

// Import Swiper modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// SCSS
import "./photos.scss";
// Assets
import MessAround from "../../assets/music/Mess Around.jpeg";
import SoEasy from "../../assets/music/So Easy.jpg";
import LetGo from "../../assets/music/Let Go.jpg";

// Components
import Title from "../ui-components/title/title";
import PhotoBox from "./photoBox";

class Blog extends React.Component {
  state = {
    // LIST ARRAY OF BLOG STORIES
    stories: [
      {
        image: MessAround,
        description: "https://distrokid.com/hyperfollow/barelystrangers/mess-around",
        id: "3",
        title: "Mess Around",
        date: "2024",
      },
      {
        image: SoEasy,
        description: "https://distrokid.com/hyperfollow/barelystrangers/so-easy",
        id: "2",
        title: "So Easy",
        date: "2024",
      },
      {
        image: LetGo,
        description: "https://distrokid.com/hyperfollow/barelystrangers/let-go",
        id: "1",
        title: "Let Go",
        date: "2024",
      },
    ],
  };

  render() {
    // BLOG STORIES RENDER
    const storiesRender = this.state.stories.map((story) => (
      <SwiperSlide key={story.id}>
        <PhotoBox article={story} />
      </SwiperSlide>
    ));

    // OPTIONS FOR BLOG SLIDER
    const params = {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
      pagination: { clickable: true },
      navigation: true,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="MY PHOTOS" />
          <p className="font12">
            Discover the latest pics that define my style.
          </p>
          <div className="padding30">
            <Swiper {...params}>
              {storiesRender}
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
