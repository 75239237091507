import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './index.scss';

const NotFound = () => {
  return (
    <div className="not-found">
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="weight800 font60">404</h1>
            <p className="font24">Page Not Found</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
