import React from "react";
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import "./footer.scss";


const partnerBox = () => (
  <div className="footer">
    <div className="wrapper">
          <div className="footer-box social-media">
          <a href="https://www.instagram.com/violaboe" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@lovliola" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
          </div>
      <p className="legal">@VBOETOGRAPHY 2024</p>
    </div>
  </div>
);

export default partnerBox;
