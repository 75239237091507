import React from "react";
import { NavLink } from 'react-router-dom';
// SCSS
import "./navbar.scss";
// Assets
import CloseIcons from '../../assets/navbar/mobile-close.svg';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

const mobileNav = (props) => (
  <div className={`mobile__navbar ${props.isOpen ? "mobile__open" : ""}`}>
    <div className="mobile__navbar-close" onClick={props.closeMobileMenu}>
      <img src={CloseIcons} alt="close" />
    </div>
    <div className="mobile__navbar-menu">
      <ul>
      <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            HOME
          </NavLink>
        </li>
        <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/about"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            ABOUT
          </NavLink>
        </li>
        {/* <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/photos"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            PHOTOS
          </NavLink>
        </li> */}
        <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/contact"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            CONTACT
          </NavLink>
        </li>
        <li className="flex-center socials">
          <a href="https://www.instagram.com/violaboe" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@lovliola" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
        </li>
      </ul>
    </div>
  </div>
);

export default mobileNav;
