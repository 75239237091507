import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "./hero.scss";

const importAll = (r) => {
  return r.keys().map(r);
};

const images = importAll(require.context('../../assets/photos', false, /\.(jpg|jpeg|png)$/));

const Hero = () => {
  const [show, setShow] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [loadedImages, setLoadedImages] = useState(8); // Load fewer images initially

  const handleShow = (image) => {
    setCurrentImage(image);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentImage(null);
  };

  const handleLoadMore = () => {
    setLoadedImages(prev => prev + 8); // Load more images in smaller batches
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        handleLoadMore();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="hero" id="hero">
      <Container>
        <Row>
          <Col>
            <h1 className="weight800 font60 text-center">VBOETOGRAPHY</h1>
          </Col>
        </Row>
        <div className="gallery">
          {images.slice(0, loadedImages).map((image, index) => (
            <div className="gallery-item" key={index} onClick={() => handleShow(image)}>
              <img src={image} alt={`hero-${index}`} className="img-fluid" onLoad={(e) => e.target.classList.add('fade-in')} />
            </div>
          ))}
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="modal-body-custom">
          {currentImage && <img src={currentImage} alt="current-view" className="img-fluid" />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Hero;
