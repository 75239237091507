import React from 'react';
import { NavLink } from 'react-router-dom';
 // SCSS
import './navbar.scss';
// Assets
// import LogoImg from '../../assets/navbar/logo.jpg';
import MobileMenuIcon from '../../assets/navbar/mobile-menu.svg';
import Camera from '../../assets/icons/camera.svg';
import {  FaInstagram,  FaTiktok } from 'react-icons/fa';



const desktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? "extraLargeNavbar" : ""}`}>
    <div className="wrapper flex-s-between">
      {/* <NavLink to="/" offset={0} duration={500}><img src={LogoImg} alt="logo" className="pointer" /></NavLink> */}
      <div className="mobile__menu" onClick={props.mobileMenuOpen}>
        <img src={MobileMenuIcon} alt="menu" />
      </div>
      <div className="desktop__menu">
        <ul className="flex-s-between">
          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/" offset={-70} duration={500}><img src={Camera} alt="menu" className='camera-icon'/>
            </NavLink>
          </li>
          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/about" offset={-70} duration={500}>ABOUT</NavLink>
          </li>
          {/* <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/photos" offset={-70} duration={500}>PHOTOS</NavLink>
          </li> */}

          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/contact" offset={-70} duration={500}>CONTACT</NavLink>
          </li>
        </ul>
      </div>
      <div className="flex-center socials-desktop">
          <a href="https://www.instagram.com/violaboe" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@lovliola" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
        </div>
    </div>
  </nav>
);

export default desktopNav;